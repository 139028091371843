import React from 'react'


const ErrorPage = () => {
  return (
    <div className='jumbroton vertical-center'>
        <div className='container d-flex justify-content-center mt-2'>
        <h1>Error, Page Not Found</h1>
        </div>
    </div>
  )
}

export default ErrorPage