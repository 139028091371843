import React from 'react'
import {Table, Card} from 'react-bootstrap'
import axios from "axios";
import { orderBy } from 'lodash'
import { useState, useEffect } from 'react'


 
const Thr2023 = () => {
    const [tehaers, setTehaer] = useState([])

    useEffect(() =>{
        getTehaer()
    }, [])

    const getTehaer = async () =>{
        const response = await axios.get('https://master.atlasvirtual.id/backend/api/thaers/getAllTehaer2023')
        setTehaer(response.data)
    }
    
    function formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      }
    
    
    return (
        <div className='container' >
            <div className="row mt-3">
                <div className="col-xxl-6 col-lg-12">   
                    <div className="card card-shadow card-responsive" id="widgetOverallViews" style={{paddingTop:20, paddingBottom:20}}>
                        <div className="card-block pb-10 pt-30">
                            <div className="row">
                                <div className="col-sm-12 col-md-6">
                                    <div className="counter counter-md text-left">
                                        <div className="counter-label font-weight-500 cyan-800 text-center" style={{fontWeight:500, color:"#3CA9BA", marginBottom:5}}>JUMLAH TERKUMPUL</div>
                                        <div className="counter-number-group text-truncate text-center" style={{color:"#46BE8A", font:"25px sans-serif", marginBottom:20}}>
                                            <span className="text-center" >{' '}</span>
                                            <>{formatNumber(tehaers.reduce((a,v) =>  a = a + v.jml , 0 ))}</>
                                        </div>                               
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="counter counter-md text-left">
                                        <div className="counter-label text-center" style={{fontWeight:500, color:"#3CA9BA", marginBottom:5}}>TOTAL PESERTA</div>
                                        <div className="counter-number-group text-truncate text-center" style={{color:"#46BE8A", font:"25px sans-serif"}}>  
                                            <span className="counter-number green-600 text-center">{tehaers.length}</span>
                                        </div>                            
                                    </div>
                                </div>                                
                            </div>              
                        </div>
                    </div>  
                    </div>
                    </div>
        <Card body className='mt-3 '><h4  style={{fontWeight:500,fontSize:14, color:"#3CA9BA", marginBottom:0}}>DAFTAR PESERTA THR 2023</h4></Card>
         <Table striped bordered hover >
                <thead style={{backgroundColor:"#bfedd8"}}>
                    <tr>
                        <th style={{width:1}}>No</th>
                        <th>Nama</th>
                        <th style={{width:1}}>Alamat</th>
                        <th style={{width:1}}>Nominal</th>
                    </tr>
                </thead>
                <tbody>       
                    {orderBy(tehaers, ['alamat'], ['asc']).map((tehaer, index) => (
                        <tr key={tehaer.id}>
                        <td>{index + 1}</td>
                        <td>{tehaer.nama}</td>
                        <td>{tehaer.alamat}</td>
                        <td>{formatNumber(tehaer.jml)}</td>       
                    </tr>
                    )) }                 
                </tbody>
            </Table>
        </div>
    )
}

export default Thr2023