import { Link } from 'react-router-dom'
import { Carousel } from 'react-bootstrap'

const Slideshow = () => {
    const imageSize={
        width:"100%"
    }
   
  return (
      <div >
    <Carousel variant="dark">
            <Carousel.Item>
            <a href="https://atlasvirtual.id">
                <img
                className="d-block"
                style={imageSize}
                src="https://atlasvirtual.my.id/1444h.jpg"
                /></a>
            </Carousel.Item>
    </Carousel>
    </div>
  )
}

export default Slideshow