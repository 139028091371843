import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Thr2023 from './component/Thr2023';
import Thr2022 from './component/Thr2022';
import Thr2021 from './component/Thr2021';
import Thr2020 from './component/Thr2020';
import Thr2019 from './component/Thr2019';
import Thr2018 from './component/Thr2018';
import Navigasi from './component/Navigasi';
import Slideshow from './component/Slideshow';
import ErrorPage from './component/ErrorPage';

function App() {
  return (
    <div style={{backgroundColor:""}}>
    <Router>
        <Navigasi />
        <Slideshow />
      <Routes>
        <Route path='/' element={<Thr2023 />}/>
        <Route path='/thr2022' element={<Thr2022 />}/>
        <Route path='/thr2021' element={<Thr2021 />}/>
        <Route path='/thr2020' element={<Thr2020 />}/>
        <Route path='/thr2019' element={<Thr2019 />}/>
        <Route path='/thr2018' element={<Thr2018 />}/>
        <Route path='*' element={<ErrorPage />}/>
      </Routes>
    </Router>
    </div>
  );
}

export default App;
