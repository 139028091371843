import useState from 'react'
import {Link} from 'react-router-dom'
import { Navbar, Nav, Offcanvas, Container } from 'react-bootstrap'

const Navigasi = () => {
    const bgDark ={
        backgroundColor:"#263238"
    }
  return (
      
    <Navbar  style={{backgroundColor:"#17365D"}}  expand={false} collapseOnSelect variant="dark">
        <Container >
            <Navbar.Brand style={{color: "white"}}>INFO THR</Navbar.Brand>
            <Navbar.Toggle aria-controls="offcanvasNavbar" />
            <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="start"
            >
            <Offcanvas.Header closeButton style={{backgroundColor:"#17365D", color:"white"}} closeVariant="white">
                <Offcanvas.Title id="offcanvasNavbarLabel">Archive</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body style={bgDark}>

                <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link eventKey="1" as={Link} to="/">THR 2023</Nav.Link>
                <Nav.Link eventKey="1" as={Link} to="/thr2022">THR 2022</Nav.Link>
                <Nav.Link eventKey="1" as={Link} to="/thr2021">THR 2021</Nav.Link>
                <Nav.Link eventKey="1" as={Link} to="/thr2020">THR 2020</Nav.Link>
                <Nav.Link eventKey="1" as={Link} to="/thr2019">THR 2019</Nav.Link>
                <Nav.Link eventKey="1" as={Link} to="/thr2018">THR 2018</Nav.Link>
                </Nav>
                
            </Offcanvas.Body>
            </Navbar.Offcanvas>
        </Container>
</Navbar>
  )
}

export default Navigasi